exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-basic-bank-account-js": () => import("./../../../src/templates/basic/bankAccount.js" /* webpackChunkName: "component---src-templates-basic-bank-account-js" */),
  "component---src-templates-basic-bank-js": () => import("./../../../src/templates/basic/bank.js" /* webpackChunkName: "component---src-templates-basic-bank-js" */),
  "component---src-templates-basic-bank-offices-js": () => import("./../../../src/templates/basic/bankOffices.js" /* webpackChunkName: "component---src-templates-basic-bank-offices-js" */),
  "component---src-templates-basic-card-category-js": () => import("./../../../src/templates/basic/cardCategory.js" /* webpackChunkName: "component---src-templates-basic-card-category-js" */),
  "component---src-templates-basic-card-js": () => import("./../../../src/templates/basic/card.js" /* webpackChunkName: "component---src-templates-basic-card-js" */),
  "component---src-templates-basic-home-js": () => import("./../../../src/templates/basic/home.js" /* webpackChunkName: "component---src-templates-basic-home-js" */),
  "component---src-templates-basic-office-js": () => import("./../../../src/templates/basic/office.js" /* webpackChunkName: "component---src-templates-basic-office-js" */),
  "component---src-templates-basic-policy-js": () => import("./../../../src/templates/basic/policy.js" /* webpackChunkName: "component---src-templates-basic-policy-js" */)
}

